import { FILER_STATUS, FilerStatus } from '../tax-eval/types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

import React from 'react'
import { TaxStateDispatch } from '../reducer'
import { useStyles } from './styles'

interface FilerStatusSelectProps {
  filerStatus: FilerStatus
  dispatch: TaxStateDispatch
}

const FilerStatusSelect = ({
  filerStatus,
  dispatch,
}: FilerStatusSelectProps) => {
  const classes = useStyles()
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Filer Status</InputLabel>
      <Select
        labelWidth={80}
        title="Filer Status"
        value={filerStatus}
        onChange={(e) =>
          dispatch({
            type: 'updateFilerStatus',
            value: e.target.value as FilerStatus,
          })
        }
      >
        {FILER_STATUS.map((t) => (
          <MenuItem key={`filer-status-${t}`} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FilerStatusSelect
