import { TaxDefinition } from '../../../../types'

const state: TaxDefinition = {
  taxType: 'state',
  brackets: [
    {
      max: 27230,
      rate: 0.0535,
    },
    {
      max: 89440,
      rate: 0.068,
    },
    {
      max: 166040,
      rate: 0.0785,
    },
    {
      rate: 0.0985,
    },
  ],
}

export default state
