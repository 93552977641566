import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
} from '@material-ui/core'
import React, { ReactNode } from 'react'
import { TaxBracketEvaluation } from '../../../tax-eval/types'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import TaxAttribute from './TaxAttribute'
import { TaxBreakdownRowProps } from './types'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  accordianDetails: {
    display: 'block',
  },
}))

export const MobileTaxBreakdownRow = ({
  rate,
  max,
  min,
  taxableIncome,
  actualTax,
  percentTax,
}: TaxBreakdownRowProps) => {
  const classes = useStyles()
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {typeof rate == 'number'
              ? `Tax Bracket - ${(rate * 100).toPrecision(4)}%`
              : rate}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordianDetails}>
          <TaxAttribute
            taxKey="Applies To"
            value={`\$${min}${typeof max == 'number' ? `-${max}` : max}`}
          />
          <TaxAttribute
            taxKey="Your Taxable Income"
            value={`\$${taxableIncome}`}
          />
          <TaxAttribute taxKey="Your Actual Tax" value={`\$${actualTax}`} />
          <TaxAttribute
            taxKey="Percent Tax"
            value={`${(percentTax * 100).toPrecision(4)}%`}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

interface TaxBreakdownProps {
  breakdown: TaxBracketEvaluation[]
  id: string
  children?: ReactNode
}

const MobileTaxBreakdown = ({ breakdown, id, children }: TaxBreakdownProps) => {
  const classes = useStyles()

  return (
    <>
      <Paper className={classes.root}>
        {breakdown.map((taxEval, index) => (
          <MobileTaxBreakdownRow
            min={index == 0 ? 0 : Number(breakdown[index - 1].bracket.max) + 1}
            max={taxEval.bracket.max == null ? '+' : taxEval.bracket.max}
            rate={taxEval.bracket.rate}
            taxableIncome={taxEval.taxableIncome}
            actualTax={taxEval.actualTax}
            percentTax={taxEval.percentTax}
            key={`tax-bracket-${taxEval.bracket.rate}`}
          />
        ))}
        {children}
      </Paper>
    </>
  )
}

export default MobileTaxBreakdown
