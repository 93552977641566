import { FederalTaxConstraint, TaxConstraint, TaxDefinition } from '../../types'

const federal: TaxDefinition = {
  taxType: 'federal',
  brackets: [
    {
      max: 9950,
      rate: 0.1,
    },
    {
      max: 40525,
      rate: 0.12,
    },
    {
      max: 86375,
      rate: 0.22,
    },
    {
      max: 164925,
      rate: 0.24,
    },
    {
      max: 209425,
      rate: 0.32,
    },
    {
      max: 523600,
      rate: 0.35,
    },
    {
      rate: 0.37,
    },
  ],
}

const tax: FederalTaxConstraint = {
  filerStatus: 'single',
  taxYear: '2021',
  socialSecurityWageBase: 142800,
  additionalMedicareThreshold: 200000,
  max401KLimit: 19500,
  max401KSoloLimit: 58000,
  maxIraLimit: 6000,
  maxHsaLimit: 3600,
  federal,
}

export default tax
