import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { TaxState, TaxStateDispatch } from '../../../reducer'

import FilerStatusSelect from '../../FilerStatusSelect'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import React from 'react'
import StandardCard from '../../StandardCard'
import StandardSlider from '../../StandardSlider'
import TaxYearSelect from '../../TaxYearSelect'
import UsStateSelect from '../../UsStateSelect'
import { useStyles } from '../../styles'

interface UserInputProps {
  dispatch: TaxStateDispatch
  state: TaxState
  onNext?: () => void
}

const UserInput = ({ dispatch, state, onNext }: UserInputProps) => {
  const classes = useStyles()

  return (
    <StandardCard mediaPath="/user-info.png" title="About You" onNext={onNext}>
      <Grid container direction="row" className={classes.alignLeft} spacing={2}>
        <Grid item xs={6}>
          <TaxYearSelect dispatch={dispatch} taxYear={state.taxYear} />
        </Grid>
        <Grid item xs={6}>
          <FilerStatusSelect
            dispatch={dispatch}
            filerStatus={state.filerStatus}
          />
        </Grid>
        <Grid item xs={12}>
          <UsStateSelect dispatch={dispatch} usState={state.usState} />
        </Grid>
      </Grid>
    </StandardCard>
  )
}

export default UserInput
