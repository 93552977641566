import { ContributionTime, ContributionType } from '../../../reducer'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'

interface BinaryCheckboxProps {
  contributionType: ContributionType
  taxTime: ContributionTime
  onChange: (
    contributionType: ContributionType,
    taxType: ContributionTime
  ) => void
}

const BinaryCheckbox = ({
  contributionType,
  taxTime,
  onChange,
}: BinaryCheckboxProps) => (
  <FormGroup row>
    <FormControlLabel
      control={
        <Checkbox
          checked={taxTime == 'Before-Tax'}
          onChange={() =>
            onChange(
              contributionType,
              taxTime == 'Before-Tax' ? 'After-Tax' : 'Before-Tax'
            )
          }
          name="preTax"
          color="primary"
        />
      }
      label="Pre-Tax"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={taxTime == 'After-Tax'}
          onChange={() =>
            onChange(
              contributionType,
              taxTime == 'After-Tax' ? 'Before-Tax' : 'After-Tax'
            )
          }
          name="postTax"
          color="primary"
        />
      }
      label="After-Tax"
    />
  </FormGroup>
)

export default BinaryCheckbox
