import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import React, { ReactNode } from 'react'
import { TaxBracketEvaluation, TaxEvaluation } from '../../../tax-eval/types'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { ScreenType } from '../../../types'
import MobileTaxBreakdown, { MobileTaxBreakdownRow } from './MobileTaxBreakdown'
import { TaxBreakdownRowProps } from './types'

interface ColumnData {
  id: string
  label: string
  minWidth: number
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
}

const columns: ColumnData[] = [
  { id: 'bracket-rate', label: 'Rate', minWidth: 100 },
  { id: 'max-bracket-income', label: 'Applies To', minWidth: 100 },
  { id: 'taxable-income', label: 'Your Taxable Income', minWidth: 100 },
  { id: 'actual-tax', label: 'Your Actual Tax', minWidth: 100 },
  { id: 'percent-tax', label: 'Your % Tax', minWidth: 100 },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  accordianDetails: {
    display: 'block',
  },
}))

const TaxBreakdownRow = ({
  rate,
  max,
  min,
  taxableIncome,
  actualTax,
  percentTax,
}: TaxBreakdownRowProps) => (
  <TableRow hover tabIndex={-1} key={`tax-bracket-${rate}`}>
    <TableCell>
      {typeof rate == 'number' ? `${(rate * 100).toPrecision(4)}%` : rate}
    </TableCell>
    <TableCell>
      ${min}
      {typeof max == 'number' ? `-${max}` : max}
    </TableCell>
    <TableCell>${taxableIncome}</TableCell>
    <TableCell>${actualTax}</TableCell>
    <TableCell>{(percentTax * 100).toPrecision(4)}%</TableCell>
  </TableRow>
)

interface TaxBreakdownProps {
  breakdown: TaxBracketEvaluation[]
  id: string
}

const TaxBreakdown = ({ breakdown, id }: TaxBreakdownProps) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={`${id}-${column.id}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {breakdown.map((taxEval, index) => (
              <TaxBreakdownRow
                min={
                  index == 0 ? 0 : Number(breakdown[index - 1].bracket.max) + 1
                }
                max={taxEval.bracket.max == null ? '+' : taxEval.bracket.max}
                rate={taxEval.bracket.rate}
                taxableIncome={taxEval.taxableIncome}
                actualTax={taxEval.actualTax}
                percentTax={taxEval.percentTax}
                key={`tax-bracket-${taxEval.bracket.rate}`}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

interface TaxAccordianProps extends TaxBreakdownProps {
  title: string
  children?: ReactNode
  screenType: ScreenType
}

export const TaxAccordian = ({
  title,
  children,
  ...props
}: TaxAccordianProps) => {
  const classes = useStyles()
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
        {props.screenType == 'xl' ? (
          <TaxBreakdown {...props} />
        ) : (
          <MobileTaxBreakdown {...props}>
            {props.id == 'federal' ? children : null}
          </MobileTaxBreakdown>
        )}
        {props.screenType == 'xl' && props.id == 'federal' && (
          <div>{children}</div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

interface FederalTaxAccordianProps {
  taxEvaluation: TaxEvaluation
  title: string
  screenType: ScreenType
}

export const FederalTaxAccordian = ({
  taxEvaluation,
  title,
  screenType,
}: FederalTaxAccordianProps) => {
  const classes = useStyles()
  return (
    <TaxAccordian
      breakdown={taxEvaluation.federal.breakdown}
      title={title}
      id="federal"
      screenType={screenType}
    >
      {screenType != 'xl' && (
        <>
          <MobileTaxBreakdownRow
            min={0}
            max={taxEvaluation.grosseIncome}
            rate="Medicare"
            taxableIncome={taxEvaluation.taxableIncome}
            actualTax={taxEvaluation.medicare}
            percentTax={taxEvaluation.medicare / taxEvaluation.grosseIncome}
          />
          <MobileTaxBreakdownRow
            min={0}
            max={taxEvaluation.socialSecurityWageBase}
            rate="Social Security"
            taxableIncome={Math.min(
              taxEvaluation.taxableIncome,
              taxEvaluation.socialSecurityWageBase
            )}
            actualTax={taxEvaluation.socialSecurity}
            percentTax={
              taxEvaluation.socialSecurity / taxEvaluation.grosseIncome
            }
          />
          <MobileTaxBreakdownRow
            min={taxEvaluation.additionalMedicareTheshold}
            max="+"
            rate="Add. Medicare"
            taxableIncome={Math.max(
              taxEvaluation.taxableIncome -
                taxEvaluation.additionalMedicareTheshold,
              0
            )}
            actualTax={taxEvaluation.additionalMedicare}
            percentTax={
              taxEvaluation.additionalMedicare / taxEvaluation.grosseIncome
            }
          />
        </>
      )}
      {screenType == 'xl' && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={`federal-mcss-${column.id}`}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <b>{index == 0 ? 'Type' : column.label}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TaxBreakdownRow
                  min={0}
                  max={taxEvaluation.grosseIncome}
                  rate="Medicare"
                  taxableIncome={taxEvaluation.taxableIncome}
                  actualTax={taxEvaluation.medicare}
                  percentTax={
                    taxEvaluation.medicare / taxEvaluation.grosseIncome
                  }
                />
                <TaxBreakdownRow
                  min={0}
                  max={taxEvaluation.socialSecurityWageBase}
                  rate="Social Security"
                  taxableIncome={Math.min(
                    taxEvaluation.taxableIncome,
                    taxEvaluation.socialSecurityWageBase
                  )}
                  actualTax={taxEvaluation.socialSecurity}
                  percentTax={
                    taxEvaluation.socialSecurity / taxEvaluation.grosseIncome
                  }
                />
                <TaxBreakdownRow
                  min={taxEvaluation.additionalMedicareTheshold}
                  max="+"
                  rate="Add. Medicare"
                  taxableIncome={Math.max(
                    taxEvaluation.taxableIncome -
                      taxEvaluation.additionalMedicareTheshold,
                    0
                  )}
                  actualTax={taxEvaluation.additionalMedicare}
                  percentTax={
                    taxEvaluation.additionalMedicare /
                    taxEvaluation.grosseIncome
                  }
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </TaxAccordian>
  )
}

export default TaxBreakdown
