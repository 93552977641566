import {
  FederalTaxConstraint,
  TaxConstraint,
  TaxDefinition,
  UsState,
} from '../types'

import Joint2020 from './2020/joint'
import Joint2021 from './2021/joint'
import Single2020 from './2020/single'
import Single2021 from './2021/single'

export function qualifyTaxConstraint(
  federalConstraint: FederalTaxConstraint,
  usState: UsState
): TaxConstraint {
  const state = require(`../brackets/${
    federalConstraint.taxYear
  }/state/${usState.toLowerCase()}/${federalConstraint.filerStatus}`)
    .default as TaxDefinition

  return {
    ...federalConstraint,
    state,
    usState,
  }
}

const federalConstraints: FederalTaxConstraint[] = [
  Single2020,
  Joint2020,
  Single2021,
  Joint2021,
]

export default federalConstraints
