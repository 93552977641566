export type ScreenType = 'sm' | 'md' | 'lg' | 'xl'

export const resolveScreenType = (innerWidth: number) => {
  if (innerWidth < 480) {
    return 'sm'
  } else if (innerWidth < 768) {
    return 'md'
  } else if (innerWidth < 1024) {
    return 'lg'
  } else {
    return 'xl'
  }
}
