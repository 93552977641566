import { TaxDefinition } from '../../../../types'

const state: TaxDefinition = {
  taxType: 'state',
  brackets: [
    {
      rate: 0.0,
    },
  ],
}

export default state
