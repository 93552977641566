import { FilerStatus, TaxEvaluation, TaxYear, UsState } from './tax-eval/types'

import calculateTax from './tax-eval'

export type ContributionType = 'ira' | 'hsa' | '401k'
export type ContributionTime = 'Before-Tax' | 'After-Tax'

type Contribution = { contributionTime: ContributionTime; amount: number }

export interface TaxState {
  grosseIncome: number
  taxYear: TaxYear
  filerStatus: FilerStatus
  usState: UsState
  selfEmployed: boolean
  retire401K: number
  ira: number
  hsa: number
  taxEvaluation?: TaxEvaluation
  contributions: Record<ContributionType, Contribution>
}

export type TaxActionKey =
  | 'updateGrosse'
  | 'updateYear'
  | 'updateState'
  | 'updateFilerStatus'
  | 'updateSelfEmployed'
  | 'updateContribution'

interface TaxAction {
  type: TaxActionKey
}

interface UpdateGrosseIncome extends TaxAction {
  type: 'updateGrosse'
  value: number
}

interface UpdateTaxYear extends TaxAction {
  type: 'updateYear'
  value: TaxYear
}

interface UpdateFilerStatus extends TaxAction {
  type: 'updateFilerStatus'
  value: FilerStatus
}

interface UpdateState extends TaxAction {
  type: 'updateState'
  value: UsState
}

interface UpdateSelfEmployed extends TaxAction {
  type: 'updateSelfEmployed'
  value: boolean
}

interface UpdateContribution {
  type: 'updateContribution'
  contributionType: ContributionType
  value: Contribution
}

type TaxStateAction =
  | UpdateFilerStatus
  | UpdateTaxYear
  | UpdateState
  | UpdateGrosseIncome
  | UpdateSelfEmployed
  | UpdateContribution

const PARTIAL_STATE: TaxState = {
  taxYear: '2021',
  filerStatus: 'single',
  usState: 'Minnesota',
  grosseIncome: 0,
  selfEmployed: false,
  retire401K: 0,
  ira: 0,
  hsa: 0,
  contributions: {
    ira: { contributionTime: 'Before-Tax', amount: 0 },
    hsa: { contributionTime: 'Before-Tax', amount: 0 },
    '401k': { contributionTime: 'Before-Tax', amount: 0 },
  },
}

export const INITIAL_STATE: TaxState = {
  ...PARTIAL_STATE,
  taxEvaluation: calculateTax(
    PARTIAL_STATE.grosseIncome,
    PARTIAL_STATE.selfEmployed,
    PARTIAL_STATE.filerStatus,
    PARTIAL_STATE.taxYear,
    PARTIAL_STATE.usState,
    PARTIAL_STATE.contributions
  ),
}

const reducer = (state: TaxState, action: TaxStateAction): TaxState => {
  let nextState
  switch (action.type) {
    case 'updateYear':
      nextState = { ...state, taxYear: action.value }
      break
    case 'updateFilerStatus':
      nextState = { ...state, filerStatus: action.value }
      break
    case 'updateGrosse':
      nextState = { ...state, grosseIncome: action.value }
      break
    case 'updateSelfEmployed':
      nextState = { ...state, selfEmployed: action.value }
      break
    case 'updateContribution':
      nextState = {
        ...state,
        contributions: {
          ...state.contributions,
          [action.contributionType]: action.value,
        },
      }
      break
    case 'updateState':
      nextState = {
        ...state,
        usState: action.value,
      }
      break
    default:
      nextState = state
  }

  return {
    ...nextState,
    taxEvaluation: calculateTax(
      nextState.grosseIncome,
      nextState.selfEmployed,
      nextState.filerStatus,
      nextState.taxYear,
      nextState.usState,
      nextState.contributions
    ),
  }
}

export type TaxStateDispatch = React.Dispatch<TaxStateAction>
export default reducer
