import { TaxDefinition } from '../../../../types'

const state: TaxDefinition = {
  taxType: 'state',
  brackets: [
    {
      max: 3000,
      rate: 0.02,
    },
    {
      max: 5000,
      rate: 0.03,
    },
    {
      max: 17000,
      rate: 0.05,
    },
    {
      rate: 0.0575,
    },
  ],
}

export default state
