import { FederalTaxConstraint, TaxDefinition } from '../../types'

const federal: TaxDefinition = {
  taxType: 'federal',
  brackets: [
    {
      max: 9875,
      rate: 0.1,
    },
    {
      max: 40125,
      rate: 0.12,
    },
    {
      max: 85525,
      rate: 0.22,
    },
    {
      max: 163300,
      rate: 0.24,
    },
    {
      max: 207350,
      rate: 0.32,
    },
    {
      max: 518400,
      rate: 0.35,
    },
    {
      max: 1000000000,
      rate: 0.37,
    },
  ],
}

const tax: FederalTaxConstraint = {
  filerStatus: 'single',
  taxYear: '2020',
  socialSecurityWageBase: 137700,
  additionalMedicareThreshold: 200000,
  federal,
  max401KLimit: 19500,
  max401KSoloLimit: 57000,
  maxIraLimit: 6000,
  maxHsaLimit: 3550,
}

export default tax
