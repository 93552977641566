import { FederalTaxConstraint, TaxDefinition } from '../../types'

const federal: TaxDefinition = {
  taxType: 'federal',
  brackets: [
    {
      max: 9950,
      rate: 0.1,
    },
    {
      max: 19900,
      rate: 0.12,
    },
    {
      max: 81050,
      rate: 0.22,
    },
    {
      max: 172750,
      rate: 0.24,
    },
    {
      max: 329850,
      rate: 0.32,
    },
    {
      max: 418850,
      rate: 0.35,
    },
    {
      rate: 0.37,
    },
  ],
}

const tax: FederalTaxConstraint = {
  filerStatus: 'joint',
  taxYear: '2021',
  socialSecurityWageBase: 142800,
  additionalMedicareThreshold: 200000,
  federal,
  max401KLimit: 19500,
  max401KSoloLimit: 58000,
  maxIraLimit: 6000,
  maxHsaLimit: 3600,
}

export default tax
