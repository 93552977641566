import { AccountBalance, Flag, ListAlt, Person } from '@material-ui/icons'
import { Grid, Grow, IconButton, SvgIcon, makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'

export type StepType =
  | 'about'
  | 'contributions'
  | 'state'
  | 'federal'
  | 'summary'

interface StepInfo {
  type: StepType
  icon: ReactNode
}

export const steps: StepInfo[] = [
  {
    type: 'about',
    icon: <Person />,
  },
  {
    type: 'contributions',
    icon: (
      <SvgIcon
        enableBackground="new 0 0 24 24"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <rect fill="none" height="24" width="24" />
        <g>
          <path d="M19.83,7.5l-2.27-2.27c0.07-0.42,0.18-0.81,0.32-1.15C17.96,3.9,18,3.71,18,3.5C18,2.67,17.33,2,16.5,2 c-1.64,0-3.09,0.79-4,2l-5,0C4.46,4,2,6.46,2,9.5S4.5,21,4.5,21l5.5,0v-2h2v2l5.5,0l1.68-5.59L22,14.47V7.5H19.83z M13,9H8V7h5V9z M16,11c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C17,10.55,16.55,11,16,11z" />
        </g>
      </SvgIcon>
    ),
  },
  {
    type: 'federal',
    icon: <Flag />,
  },
  {
    type: 'state',
    icon: <AccountBalance />,
  },
  {
    type: 'summary',
    icon: <ListAlt />,
  },
]

export const getNextStep = (currentStep: StepType) => {
  switch (currentStep) {
    case 'about':
      return 'contributions'
    case 'contributions':
      return 'federal'
    case 'federal':
      return 'state'
    case 'state':
      return 'summary'
    default:
      return 'summary'
  }
}

interface StepProps {
  id: StepType
  currentStep: StepType
  xs: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  children: ReactNode
}

const useStyles = makeStyles({
  hidden: {
    height: 0,
  },
})

const Step = ({ id, children, xs, currentStep }: StepProps) => {
  const isCurrent = currentStep == id || currentStep == 'summary'
  const classes = useStyles()
  return (
    <Grow in={isCurrent} timeout={currentStep == id ? undefined : 0}>
      <Grid item xs={xs} className={isCurrent ? '' : classes.hidden}>
        {children}
      </Grid>
    </Grow>
  )
}

export default Step
