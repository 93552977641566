import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 324,
    width: '100%',
  },
  alignLeft: {
    textAlign: 'left',
  },
  singleRow: {
    width: '92%',
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  infoUrl: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}))
