import { AccountBalance, Flag, ListAlt, Person } from '@material-ui/icons'
import { Button, Menu } from '@material-ui/core'
import { StepType, steps } from './steps/Step'

import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Profile from './Profile'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from '@auth0/auth0-react'

const appBarColor = '#2596be'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  active: {
    marginRight: theme.spacing(2),
    background: 'white',
    color: appBarColor,
    '&:hover': {
      background: 'white',
      color: appBarColor,
    },
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: appBarColor,
    display: 'flex',
  },
  profile: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
  },
}))

const NavBar = ({
  setState,
  step,
}: {
  setState: (value: StepType) => void
  step: StepType
}) => {
  const { user, isAuthenticated, loginWithRedirect, isLoading } = useAuth0()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.toolbar}>
        <Toolbar>
          {steps.map((s) => (
            <IconButton
              key={`step-icon-${s.type}`}
              edge="start"
              id={`step-icon-${s.type}`}
              className={step == s.type ? classes.active : classes.menuButton}
              color="inherit"
              aria-label="State"
              onClick={() => setState(s.type)}
            >
              {s.icon}
            </IconButton>
          ))}
          <div className={classes.profile}>
            {!isAuthenticated && !isLoading && (
              <Button color="inherit" onClick={loginWithRedirect}>
                Login
              </Button>
            )}

            {isAuthenticated && <Profile />}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar
