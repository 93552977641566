import { TaxDefinition } from '../../../../types'

const state: TaxDefinition = {
  taxType: 'state',
  brackets: [
    {
      max: 39410,
      rate: 0.0535,
    },
    {
      max: 156570,
      rate: 0.0705,
    },
    {
      max: 273471,
      rate: 0.0785,
    },
    {
      rate: 0.0985,
    },
  ],
}

export default state
