import { createMuiTheme } from '@material-ui/core/styles'
import green from '@material-ui/core/colors/green'
import purple from '@material-ui/core/colors/purple'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2596be',
    },
    secondary: {
      main: purple[500],
    },
  },
})

export default theme
