import { FormControl, Grid, TextField } from '@material-ui/core'

import React from 'react'
import { TaxResult } from '../../../tax-eval/types'
import { useStyles } from '../../styles'

const TaxAttribute = ({
  taxKey,
  value,
  infoUrl,
}: {
  taxKey: string
  value: string | number
  infoUrl?: string
}) => {
  const classes = useStyles()
  return (
    <FormControl fullWidth variant="outlined" style={{ marginTop: '10px' }}>
      <TextField
        label={
          infoUrl != null ? (
            <a className={classes.infoUrl} href={infoUrl} target="_blank">
              {taxKey}
            </a>
          ) : (
            taxKey
          )
        }
        value={value}
        InputProps={{
          readOnly: true,
        }}
      />
    </FormControl>
  )
}

export const IncomeTaxAttributes = ({
  taxResult,
}: {
  taxResult: TaxResult
}) => (
  <>
    <Grid item xs={12}>
      <TaxAttribute taxKey="Income Tax" value={`$${taxResult.tax}`} />
    </Grid>
    <Grid item xs={12}>
      <TaxAttribute
        taxKey="Effective Tax Rate"
        infoUrl="https://www.investopedia.com/terms/e/effectivetaxrate.asp"
        value={`${(taxResult.effectiveTaxRate * 100).toPrecision(4)}%`}
      />
    </Grid>
    <Grid item xs={12}>
      <TaxAttribute
        taxKey="Marginal Tax Rate"
        infoUrl="https://www.investopedia.com/terms/m/marginaltaxrate.asp"
        value={`${(taxResult.marginalTaxRate * 100).toPrecision(4)}%`}
      />
    </Grid>
  </>
)

export default TaxAttribute
