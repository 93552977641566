import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { TAX_YEARS, TaxYear } from '../tax-eval/types'

import React from 'react'
import { TaxStateDispatch } from '../reducer'

interface TaxSelectProps {
  taxYear: TaxYear
  dispatch: TaxStateDispatch
}

const TaxYearSelect = ({ taxYear, dispatch }: TaxSelectProps) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Tax Year</InputLabel>
      <Select
        labelWidth={60}
        title="Tax Type"
        value={taxYear}
        onChange={(e) =>
          dispatch({ type: 'updateYear', value: e.target.value as TaxYear })
        }
      >
        {TAX_YEARS.map((t) => (
          <MenuItem key={`tax-year-${t}`} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default TaxYearSelect
