import './App.css'

import { Container, Grid, ThemeProvider, makeStyles } from '@material-ui/core'
import {
  FederalTaxAccordian,
  TaxAccordian,
} from './components/steps/tax/TaxBreakdown'
import {
  FederalTaxCard,
  StateTaxCard,
  SummaryTaxCard,
} from './components/steps/tax/TaxCard'
import React, { useEffect, useReducer, useState } from 'react'
import { ScreenType, resolveScreenType } from './types'
import Step, { StepType, getNextStep } from './components/steps/Step'
import appReducer, { INITIAL_STATE, TaxState } from './reducer'

import Contributions from './components/steps/user/Contributions'
import NavBar from './components/NavBar'
import UserInput from './components/steps/user/UserInput'
import theme from './theme'
import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  hidden: {
    height: 0,
  },
}))

function App() {
  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE)

  const [step, setStep] = useState<StepType>('about')
  const [screenType, setScreenType] = useState<ScreenType>(
    resolveScreenType(window.screen.width)
  )

  useEffect(() => {
    if (window != null) {
      window.addEventListener('resize', (e) => {
        // @ts-ignore
        setScreenType(resolveScreenType(e.target.innerWidth))
      })
    }
  }, [])

  const classes = useStyles()

  function onNext() {
    setStep((prev) => getNextStep(prev))
  }

  function onPrev() {
    setStep((prev) => getNextStep(prev))
  }

  const xs = step == 'summary' && screenType == 'xl' ? 3 : 12

  const next = step == 'summary' ? undefined : onNext
  const prev = step == 'summary' ? undefined : onPrev

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <NavBar setState={setStep} step={step} />
        {state.taxEvaluation && (
          <Container className={classes.root} maxWidth={false}>
            <Grid container spacing={1} alignContent="center">
              <Step id="about" xs={xs} currentStep={step}>
                <UserInput state={state} dispatch={dispatch} onNext={next} />
              </Step>
              <Step id="contributions" xs={xs} currentStep={step}>
                <Contributions
                  state={state}
                  dispatch={dispatch}
                  onNext={next}
                />
              </Step>
              <Step id="federal" xs={xs} currentStep={step}>
                <FederalTaxCard
                  taxResult={state.taxEvaluation.federal}
                  medicare={state.taxEvaluation.medicare}
                  additionalMedicare={state.taxEvaluation.additionalMedicare}
                  socialSecurity={state.taxEvaluation.socialSecurity}
                  onNext={next}
                  onPrev={prev}
                />
              </Step>
              <Step id="state" xs={xs} currentStep={step}>
                <StateTaxCard
                  usState={state.usState}
                  taxResult={state.taxEvaluation.state}
                  onNext={next}
                  onPrev={prev}
                />
              </Step>
              <Step id="summary" xs={xs} currentStep={step}>
                <SummaryTaxCard
                  contributions={state.contributions}
                  tax={state.taxEvaluation.total}
                  grosseIncome={state.taxEvaluation.grosseIncome}
                  taxableIncome={state.taxEvaluation.taxableIncome}
                  percentTax={state.taxEvaluation.effectiveRate}
                  onPrev={onPrev}
                />
              </Step>

              {state.grosseIncome > 0 && (
                <>
                  <Step id="federal" xs={12} currentStep={step}>
                    <FederalTaxAccordian
                      title="Federal Tax Breakdown"
                      taxEvaluation={state.taxEvaluation}
                      screenType={screenType}
                    />
                  </Step>
                  <Step id="state" xs={12} currentStep={step}>
                    <TaxAccordian
                      title="State Tax Breakdown"
                      breakdown={state.taxEvaluation.state.breakdown}
                      id="state"
                      screenType={screenType}
                    />
                  </Step>
                </>
              )}
            </Grid>
          </Container>
        )}
      </div>
    </ThemeProvider>
  )
}

export default App
