import { Button, CardActionArea, CardMedia } from '@material-ui/core'
import React, { ReactNode } from 'react'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

interface StandardCardProps {
  mediaPath: string
  title: string
  children: ReactNode
  className?: string
  onNext?: () => void
  onPrev?: () => void
}

type NextPrevProps = Pick<StandardCardProps, 'onNext' | 'onPrev'>

const NextPrev = ({ onNext, onPrev }: NextPrevProps) => {
  return (
    <>
      {onPrev && (
        <Button size="small" color="secondary" onClick={onPrev}>
          Back
        </Button>
      )}
      {onNext && (
        <Button size="small" color="primary" onClick={onNext}>
          Next
        </Button>
      )}
    </>
  )
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: '0 auto',
    marginTop: '10px',
    paddingTop: 10,
  },
  media: {
    height: 80,
    width: 80,
    margin: '0 auto',
  },
})

const StandardCard = ({
  mediaPath,
  title,
  children,
  onNext,
  onPrev,
  className,
}: StandardCardProps) => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={className == null ? classes.media : className}
          image={mediaPath}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            <b>{title}</b>
          </Typography>
          {children}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <NextPrev onNext={onNext} onPrev={onPrev}></NextPrev>
      </CardActions>
    </Card>
  )
}

export default StandardCard
