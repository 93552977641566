import {
  FILER_STATUS,
  FilerStatus,
  US_STATES,
  UsState,
} from '../tax-eval/types'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

import React from 'react'
import { TaxStateDispatch } from '../reducer'
import { useStyles } from './styles'

interface UsStateSelectProps {
  usState: UsState
  dispatch: TaxStateDispatch
}

const UsStateSelect = ({ usState, dispatch }: UsStateSelectProps) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>State</InputLabel>
      <Select
        labelWidth={35}
        title="State"
        value={usState}
        onChange={(e) =>
          dispatch({
            type: 'updateState',
            value: e.target.value as UsState,
          })
        }
      >
        {US_STATES.map((t) => (
          <MenuItem key={`filer-status-${t}`} value={t}>
            {t}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default UsStateSelect
