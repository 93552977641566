import {
  FilerStatus,
  RetirementContributions,
  TaxBracketEvaluation,
  TaxConstraint,
  TaxDefinition,
  TaxEvaluation,
  TaxResult,
  TaxYear,
  UsState,
} from './types'
import brackets, { qualifyTaxConstraint } from './brackets'

import { ContributionTime } from '../reducer'

const calculateIncomeTax = (
  taxableIncome: number,
  tax: TaxDefinition
): TaxResult => {
  const { brackets } = tax
  let [effectiveTaxIndex, incomeTax, leftInterval, marginalTaxRate] = [
    0,
    0,
    0,
    0,
  ]
  const breakdown: TaxBracketEvaluation[] = []

  while (effectiveTaxIndex < brackets.length && taxableIncome > leftInterval) {
    const { max, rate } = brackets[effectiveTaxIndex]
    const rightInterval =
      max != null && taxableIncome > max ? max : taxableIncome
    const taxableIncomeAtBracket = rightInterval - leftInterval
    const actualTax = Number((taxableIncomeAtBracket * rate).toPrecision(4))
    const percentTax = Number((actualTax / taxableIncome).toPrecision(4))

    breakdown.push({
      bracket: brackets[effectiveTaxIndex],
      percentTax,
      taxableIncome: taxableIncomeAtBracket,
      actualTax,
    })

    incomeTax += actualTax
    leftInterval = max != null ? max + 1 : 0
    marginalTaxRate = rate
    ++effectiveTaxIndex
  }

  return {
    tax: Number(incomeTax.toPrecision(4)),
    marginalTaxRate,
    effectiveTaxRate: Number(
      (incomeTax / Math.max(taxableIncome, 1)).toPrecision(4)
    ),
    breakdown,
  }
}

const calculateSocialSecurity = (
  taxableIncome: number,
  selfEmployed: boolean,
  taxConstraint: TaxConstraint
): number =>
  Number(
    (
      Math.min(taxableIncome, taxConstraint.socialSecurityWageBase) *
      0.062 *
      (selfEmployed ? 2 : 1)
    ).toFixed(2)
  )

const calculateMedicare = (taxableIncome: number, selfEmployed: boolean) =>
  Number((taxableIncome * 0.0145 * (selfEmployed ? 2 : 1)).toFixed(2))

const calculateAdditionalMedicare = (
  taxableIncome: number,
  taxConstraint: TaxConstraint
) =>
  Number(
    (
      Math.max(taxableIncome - taxConstraint.additionalMedicareThreshold, 0) *
      0.009
    ).toFixed(2)
  )

const getPreTaxContribution = ({
  contributionTime,
  amount,
}: {
  contributionTime: ContributionTime
  amount: number
}) => (contributionTime == 'Before-Tax' ? amount : 0)

const calculateTaxableIncome = (
  grosseIncome: number,
  contributions: RetirementContributions
) =>
  grosseIncome -
  getPreTaxContribution(contributions.ira) -
  getPreTaxContribution(contributions['401k']) -
  getPreTaxContribution(contributions.hsa)

export const findTaxConstraint = (taxYear: TaxYear, filerStatus: FilerStatus) =>
  brackets.find((t) => t.taxYear == taxYear && t.filerStatus == filerStatus)

const calculateTax = (
  grosseIncome: number,
  selfEmployed: boolean,
  filerStatus: FilerStatus,
  taxYear: TaxYear,
  usState: UsState,
  contributions: RetirementContributions
): TaxEvaluation => {
  const federalConstraint = findTaxConstraint(taxYear, filerStatus)

  if (federalConstraint == null) {
    throw new Error(
      JSON.stringify({
        message: 'Could not find tax definition for filer status and year.',
        filerStatus,
        taxYear,
      })
    )
  }

  const taxConstraint = qualifyTaxConstraint(federalConstraint, usState)

  const taxableIncome = calculateTaxableIncome(grosseIncome, contributions)

  const federal = calculateIncomeTax(taxableIncome, taxConstraint.federal)
  const state = calculateIncomeTax(taxableIncome, taxConstraint.state)
  const socialSecurity = calculateSocialSecurity(
    taxableIncome,
    selfEmployed,
    taxConstraint
  )

  const medicare = calculateMedicare(taxableIncome, selfEmployed)
  const additionalMedicare = calculateAdditionalMedicare(
    taxableIncome,
    taxConstraint
  )

  const total =
    federal.tax + state.tax + medicare + additionalMedicare + socialSecurity

  return {
    effectiveRate: Number((total / Math.max(1, grosseIncome)).toPrecision(4)),
    medicare,
    additionalMedicare,
    additionalMedicareTheshold: taxConstraint.additionalMedicareThreshold,
    socialSecurity,
    socialSecurityWageBase: taxConstraint.socialSecurityWageBase,
    grosseIncome,
    taxableIncome,
    taxConstraint,
    federal,
    state,
    total,
  }
}

export default calculateTax
