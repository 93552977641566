import {
  FederalIncomeTaxProps,
  StateIncomeTaxProps,
  SummaryTaxProps,
} from './types'
import { Grid, makeStyles } from '@material-ui/core'
import TaxAttribute, { IncomeTaxAttributes } from './TaxAttribute'

import React from 'react'
import StandardCard from '../../StandardCard'

const useStyles = makeStyles(() => ({
  federal: {
    height: 80,
    width: 80,
    margin: '0 auto',
  },
}))

export const StateTaxCard = ({
  taxResult,
  onNext,
  onPrev,
  usState,
}: StateIncomeTaxProps) => (
  <StandardCard
    mediaPath="/state-tax.jpg"
    title="State"
    onNext={onNext}
    onPrev={onPrev}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TaxAttribute taxKey="State" value={usState} />
      </Grid>
      <IncomeTaxAttributes taxResult={taxResult} />
    </Grid>
  </StandardCard>
)

export const FederalTaxCard = ({
  taxResult,
  medicare,
  additionalMedicare,
  socialSecurity,
  onNext,
  onPrev,
}: FederalIncomeTaxProps) => {
  const classes = useStyles()
  return (
    <StandardCard
      mediaPath="/ftax.jpg"
      title="Federal"
      onNext={onNext}
      onPrev={onPrev}
      className={classes.federal}
    >
      <Grid container spacing={2}>
        <IncomeTaxAttributes taxResult={taxResult} />
        <Grid item xs={12}>
          <TaxAttribute
            taxKey="Medicare"
            value={`$${medicare}`}
            infoUrl="https://www.irs.gov/taxtopics/tc751"
          />
        </Grid>
        <Grid item xs={12}>
          <TaxAttribute
            taxKey="Add. Medicare"
            value={`$${additionalMedicare}`}
            infoUrl="https://www.irs.gov/newsroom/what-is-the-additional-medicare-tax"
          />
        </Grid>
        <Grid item xs={12}>
          <TaxAttribute
            taxKey="Social Security"
            value={`$${socialSecurity}`}
            infoUrl="https://www.irs.gov/taxtopics/tc751"
          />
        </Grid>
      </Grid>
    </StandardCard>
  )
}

export const SummaryTaxCard = ({
  grosseIncome,
  taxableIncome,
  tax,
  percentTax,
  contributions,
  onPrev,
}: SummaryTaxProps) => (
  <StandardCard mediaPath="/summary.jpg" title="Summary" onPrev={onPrev}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TaxAttribute taxKey="Grosse income" value={`$${grosseIncome}`} />
      </Grid>
      <Grid item xs={12}>
        <TaxAttribute taxKey="Taxable income" value={`$${taxableIncome}`} />
      </Grid>
      <Grid item xs={12}>
        <TaxAttribute taxKey="Tax" value={`$${tax.toFixed(2)}`} />
      </Grid>
      <Grid item xs={12}>
        <TaxAttribute
          taxKey="Total Tax Rate"
          value={`${(percentTax * 100).toPrecision(4)}%`}
        />
      </Grid>
      <Grid item xs={12}>
        <TaxAttribute
          taxKey="Net Salary"
          value={`$${(grosseIncome - tax).toFixed(2)}`}
        />
      </Grid>
      <Grid item xs={12}>
        <TaxAttribute
          taxKey="After Investments"
          value={`$${(
            grosseIncome -
            tax -
            contributions['401k'].amount -
            contributions.hsa.amount -
            contributions.ira.amount
          ).toFixed(2)}`}
        />
      </Grid>
    </Grid>
  </StandardCard>
)
