import { ContributionTime } from '../reducer'

export const FILER_STATUS = ['single', 'joint'] as const
export type FilerStatus = typeof FILER_STATUS[number]

export const TAX_TYPES = ['state', 'federal'] as const
export type TaxType = typeof TAX_TYPES[number]

export const TAX_YEARS = ['2020', '2021'] as const
export type TaxYear = typeof TAX_YEARS[number]

export const US_STATES = ['Minnesota', 'Virginia', 'Tennessee'] as const
export type UsState = typeof US_STATES[number]

export interface TaxBracket {
  rate: number
  max?: number
}

export interface TaxDefinition {
  taxType: TaxType
  brackets: TaxBracket[]
}

export interface ContributionConstants {
  socialSecurityWageBase: number
  additionalMedicareThreshold: number
  max401KLimit: number
  max401KSoloLimit: number
  maxIraLimit: number
  maxHsaLimit: number
}

export interface FederalTaxConstraint extends ContributionConstants {
  taxYear: TaxYear
  filerStatus: FilerStatus
  federal: TaxDefinition
}

export interface TaxConstraint extends FederalTaxConstraint {
  usState: UsState
  state: TaxDefinition
}

export interface TaxBracketEvaluation {
  bracket: TaxBracket
  taxableIncome: number
  actualTax: number
  percentTax: number
}

export interface TaxResult {
  tax: number
  marginalTaxRate: number
  effectiveTaxRate: number
  breakdown: TaxBracketEvaluation[]
}

export interface FicaTaxResult {
  tax: number
  medicare: {
    percent: number
    total: number
  }
  socialSecurity: {
    percent: number
    total: number
  }
}

export interface FederalTaxResult extends TaxResult {
  medicare: number
  socialSecurity: number
}

export interface TaxEvaluation {
  grosseIncome: number
  taxableIncome: number
  medicare: number
  additionalMedicare: number
  additionalMedicareTheshold: number
  socialSecurity: number
  socialSecurityWageBase: number
  federal: TaxResult
  state: TaxResult
  taxConstraint: TaxConstraint
  total: number
  effectiveRate: number
}

export interface RetirementContributions {
  '401k': { amount: number; contributionTime: ContributionTime }
  ira: { amount: number; contributionTime: ContributionTime }
  hsa: { amount: number; contributionTime: ContributionTime }
}
