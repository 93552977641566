import React from 'react'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
}))

interface StandardSliderProps {
  title: string
  min: number
  max: number
  onChange: (value: number) => void
}

const StandardSlider = ({ title, min, max, onChange }: StandardSliderProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-custom" gutterBottom>
        {title}
      </Typography>
      <Slider
        key={title.toLowerCase().replaceAll(' ', '_')}
        defaultValue={0}
        valueLabelDisplay="auto"
        marks={[
          { value: min, label: `$${min}` },
          { value: max, label: `$${max}` },
        ]}
        onChange={(_, v) => onChange(v as number)}
        min={min}
        max={max}
      />
    </div>
  )
}

export default StandardSlider
