import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { MouseEvent, MouseEventHandler, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  userName: {
    color: '#2596be',
    fontWeight: 'bold',
  },
  welcomeCard: {
    width: '290px',
    textAlign: 'center',
    padding: '10px',
    boxShadow: '0 1px 3px 0 rgb(0 0 0 / 20%)',
  },
  welcomeContent: {
    fontSize: '14px !important',
  },
  logout: {
    width: '100%',
    backgroundColor: '#2596be',
    color: 'white',
    textAlign: 'center',
    '&:hover,&:focus': {
      backgroundColor: '#35A6bF',
    },
    textTransform: 'none',
  },
  primary: {
    cursor: 'pointer',
    color: '#2596be',
  },
}))

const StyledMenu = withStyles({
  list: {
    paddingTop: '0',
    paddingBottom: 0,
  },
})(Menu)

const Profile = () => {
  const classes = useStyles()
  const { user, logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const handleClick: MouseEventHandler = (
    event: MouseEvent<HTMLAnchorElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Avatar
        aria-controls="simple-menu"
        aria-haspopup="true"
        alt={user?.name}
        src={user?.picture}
        className={classes.primary}
        onClick={handleClick}
      >
        {(user?.name ?? 'P').charAt(0)}
      </Avatar>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.welcomeCard}>
          <Typography className={classes.welcomeContent}>
            Welcome,{' '}
            <span className={classes.userName}>
              {user?.preferred_username || user?.name}!
            </span>
          </Typography>
        </div>
        <MenuItem>
          <Button
            onClick={() => {
              logout({ returnTo: window.location.origin })
            }}
            className={classes.logout}
          >
            Log Out
          </Button>
        </MenuItem>
      </StyledMenu>
    </>
  )
}

export default Profile
