import {
  ContributionTime,
  ContributionType,
  TaxState,
  TaxStateDispatch,
} from '../../../reducer'
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
} from '@material-ui/core'

import BinaryCheckbox from './BinaryCheckbox'
import React from 'react'
import StandardCard from '../../StandardCard'
import StandardSlider from '../../StandardSlider'
import { useStyles } from '../../styles'

interface UserInputProps {
  dispatch: TaxStateDispatch
  state: TaxState
  onNext?: () => void
}

const Contributions = ({ dispatch, state, onNext }: UserInputProps) => {
  const classes = useStyles()

  function updateContributionTime(
    contributionType: ContributionType,
    contributionTime: ContributionTime
  ) {
    dispatch({
      type: 'updateContribution',
      contributionType,
      value: {
        ...state.contributions[contributionType],
        contributionTime,
      },
    })
  }

  function updateContributionAmount(
    contributionType: ContributionType,
    amount: number
  ) {
    dispatch({
      type: 'updateContribution',
      contributionType,
      value: {
        ...state.contributions[contributionType],
        amount,
        contributionTime:
          state.selfEmployed && contributionType == '401k'
            ? 'Before-Tax'
            : state.contributions[contributionType].contributionTime,
      },
    })
  }

  return (
    <StandardCard
      mediaPath="/piggy-bank.jpg"
      title="Income And Retirement"
      onNext={onNext}
    >
      <Grid container direction="row" className={classes.alignLeft} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Grosse Income
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={state.grosseIncome}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return

                const totalContributions =
                  state.contributions.ira.amount +
                  state.contributions.hsa.amount +
                  state.contributions['401k'].amount

                const value = Number(e.target.value.replace(/^0+/, ''))
                if (totalContributions <= value) {
                  dispatch({
                    type: 'updateGrosse',
                    value,
                  })
                }
              }}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              labelWidth={110}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={state.selfEmployed}
                onChange={() =>
                  dispatch({
                    type: 'updateSelfEmployed',
                    value: !state.selfEmployed,
                  })
                }
                name="selfEmployed"
              />
            }
            label="Self-Employed"
          />
        </Grid>
        <Grid item xs={12}>
          {!state.selfEmployed &&
            state.taxEvaluation != null &&
            state.grosseIncome != 0 && (
              <>
                <BinaryCheckbox
                  contributionType="401k"
                  taxTime="Before-Tax"
                  onChange={updateContributionTime}
                />
                <StandardSlider
                  title="401(k) Contribution"
                  min={0}
                  max={Math.min(
                    state.taxEvaluation.grosseIncome -
                      state.contributions.ira.amount -
                      state.contributions.hsa.amount,
                    state.taxEvaluation.taxConstraint.max401KLimit
                  )}
                  onChange={(value: number) =>
                    updateContributionAmount('401k', value)
                  }
                />
              </>
            )}
          {state.selfEmployed &&
            state.taxEvaluation != null &&
            state.grosseIncome != 0 && (
              <>
                <StandardSlider
                  title="Solo 401(k) Contribution"
                  min={0}
                  max={Math.min(
                    state.taxEvaluation.grosseIncome -
                      state.contributions.ira.amount -
                      state.contributions.hsa.amount,
                    state.taxEvaluation.taxConstraint.max401KSoloLimit
                  )}
                  onChange={(value: number) =>
                    updateContributionAmount('401k', value)
                  }
                />
              </>
            )}
          <Divider />
          {state.taxEvaluation != null && state.grosseIncome != 0 && (
            <>
              <BinaryCheckbox
                contributionType="ira"
                taxTime="Before-Tax"
                onChange={updateContributionTime}
              />
              <StandardSlider
                title="IRA Contribution"
                min={0}
                max={Math.min(
                  state.taxEvaluation.grosseIncome -
                    state.contributions['401k'].amount -
                    state.contributions.hsa.amount,
                  state.taxEvaluation.taxConstraint.maxIraLimit
                )}
                onChange={(value: number) =>
                  updateContributionAmount('ira', value)
                }
              />
            </>
          )}
          <Divider />
          {state.taxEvaluation != null && state.grosseIncome != 0 && (
            <>
              <BinaryCheckbox
                contributionType="hsa"
                taxTime="Before-Tax"
                onChange={updateContributionTime}
              />
              <StandardSlider
                title="HSA Contribution"
                min={0}
                max={Math.min(
                  state.taxEvaluation.grosseIncome -
                    state.contributions['401k'].amount -
                    state.contributions.ira.amount,
                  state.taxEvaluation.taxConstraint.maxHsaLimit
                )}
                onChange={(value: number) =>
                  updateContributionAmount('hsa', value)
                }
              />
            </>
          )}
        </Grid>
      </Grid>
    </StandardCard>
  )
}

export default Contributions
